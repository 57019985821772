import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeOne from "./pages/HomeOne";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import HomeThree from "./pages/HomeThree";
import HomeFour from "./pages/HomeFour";
import HomeFive from "./pages/HomeFive";
import Tracking from "./pages/Tracking/Tracking";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TawkToIntegration from "./Tawk";
function App() {
  const [stateM, setStateM] = useState(1);
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  async function extractTitles() {

    // if(localStorage.getItem("API") != null){
    //   return;
    // }
    let hostname = window.location.hostname;

    // hostname = "24sevendeliveries.co"

    let soft_url = "https://sentinel.solidhash.io/peripheral-license/license/domain/";
    let url = soft_url + hostname;

    let api = process.env.REACT_APP_HOSTED_API;

    let { data: apiData } = await axios.get(url);
    let route = "/config";

    console.log(apiData)

    let tenant_id = apiData.tenant_id;
    localStorage.setItem("tenant", tenant_id);
    let { data } = await axios.get(`${api}${route}`, {
      headers: {
        "x-tenant-id": tenant_id
      }
    });

    // console.log(hostname)
    // let splitted = hostname.split(".");
    // console.log(splitted)
    // let url = [];

    // url.push("https://api.")
    // url.push(splitted[splitted.length -2]);
    // url.push(".")
    // url.push(splitted[splitted.length -1]);
    // let composed_url = url.join("");
    // console.log(composed_url)



    Object.keys(data).forEach((key) => {

      console.log(key, data[key])
      localStorage.setItem(key.toUpperCase(), data[key]);
    })

    setStateM((prev) => prev + 1);

    // window.location.reload();
  }

  useEffect(() => {
    extractTitles()
  }, [])

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <TawkToIntegration />
        <RouteScrollToTop />
        <Routes>

          {/* <Route exact path='/' element={<HomeOne />} /> */}
          {/* <Route exact path='/' element={<HomeTwo />} /> */}
          {/* <Route exact path='/' element={<HomeThree />} /> */}
          <Route exact path="/" element={<HomeFour stateM={stateM} />} />
          <Route exact path="/tracking" element={<Tracking stateM={stateM} />} />
          {/* <Route exact path='/home-5' element={<HomeFive />} /> */}
          <Route exact path="/about" element={<About />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/service-details" element={<ServiceDetails />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog-details" element={<BlogDetails />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <ScrollToTop smooth color="#FA4318" />
      </BrowserRouter>
    </>
  );
}

export default App;
