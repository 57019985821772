import React, { useEffect } from "react";
import {
  FaArrowRight,
  FaFacebookF,
  FaLinkedinIn,
  FaPaperPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";

const FooterOne = ({stateM}) => {
  const [address1, setAddress1] = useState(localStorage.getItem("ADDRESS1"));
  const [address2, setAddress2] = useState(localStorage.getItem("ADDRESS2"));
  const [email, setEmail] = useState(`${localStorage.getItem("EMAIL_PREFIX")}@${localStorage.getItem("DOMAIN")}`);
  const [phone, setPhone] = useState(localStorage.getItem("PHONE"));
  const [logo, setLogo] = useState(localStorage.getItem("LOGO"));




  useEffect(() => {
    console.log("seen")
    setAddress1(localStorage.getItem("ADDRESS1"));
    setAddress2(localStorage.getItem("ADDRESS2"));
    setEmail(`${localStorage.getItem("EMAIL_PREFIX")}@${localStorage.getItem("DOMAIN")}`);
    setPhone(localStorage.getItem("PHONE"));
    setLogo(localStorage.getItem("LOGO"));
  }, [
    stateM
  ]);


  return (
    <>
      {/* footer area start */}
      <footer className='footer-area'>
        <div
          className='footer-top'
          style={{ backgroundImage: 'url("./assets/img/footer/bg.png")' }}
        >
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top'>
                  <div className='icon'>
                    <img src='assets/img/icon/map-marker.png' alt='Transpro' />
                  </div>
                  <div className='details'>
                    <h6>OFFICE ADDRESS:</h6>
                    <p>{address1}</p>
                    <p>{address2}</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top'>
                  <div className='icon'>
                    <img src='assets/img/icon/phone.png' alt='Transpro' />
                  </div>
                  <div className='details'>
                    <h6>CONTACT US:</h6>
                    <p>{email}</p>
                    <p>{phone}</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-footer-top after-none'>
                  <div className='icon'>
                    <img src='assets/img/icon/clock.png' alt='Transpro' />
                  </div>
                  <div className='details'>
                    <h6>WORKING HOURS:</h6>
                    <p>Weekdays - Mon-Fri: 8am - 9pm</p>
                    <p>Weekend - Sat &amp; Sun: Closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src={logo} alt='Transpro' />
                </div>
                <div className='details'>
                  <p>
                    Our experienced team of experts and cutting age technologies,with over 120 state of the art facilities worldwide. We are creating solutions for your transportation challanges,making your business activities easier and taking your goods across the globe.
                  </p>
                  <ul className='social-media style-border'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaWhatsapp />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className='col-xl-2 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>USEFULL LINKS</h4>
                <ul>
                  <li>
                    <Link to='/about'>
                      <FaArrowRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Services
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <FaArrowRight /> Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link to='/blog'>
                      <FaArrowRight />
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <FaArrowRight /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className='col-xl-2 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>OUR SERVICES</h4>
                <ul>
                  <li>
                    <a href='#'>
                      <FaArrowRight /> Air Freight
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaArrowRight /> Ocean Freight
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaArrowRight /> Railway Freight
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaArrowRight /> Warehousing
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaArrowRight /> Distribution
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className='col-xl-4 col-md-6'>
              <div className='widget widget_subscribe'>
                <h4 className='widget-title'>SUBSCRIBE NOW</h4>
                <p>
                  Continually evolve worldwide vortals rather than process
                  centric human capital. Subscribe for our latest news &amp;
                  articles. and send message.
                </p>
                <div className='single-subscribe-inner'>
                  <input type='text' placeholder='Email Address' />
                  <a className='btn btn-base' href='#'>
                    <FaPaperPlane />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      {/* footer area end */}
    </>
  );
};

export default FooterOne;
