import React, { useState, useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import styles from "./styles.module.css";

const AnimatedRoute = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setIsAnimating((prevIsAnimating) => !prevIsAnimating);
    }, 2000);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  return (
    <div className={styles.container}>
      <MdArrowForwardIos
        className={[styles.arrow, isAnimating ? styles.right : ""].join(" ")}
      />{" "}
      <MdArrowForwardIos
        className={[
          styles.arrow,
          styles.secondArrow,
          isAnimating ? styles.right : "",
        ].join(" ")}
      />{" "}

      <MdArrowForwardIos
        className={[
          styles.arrow,
          styles.secondArrow,
          styles.thirdArrow,
          isAnimating ? styles.right : "",
        ].join(" ")}
      />
    </div>
  );
};

export default AnimatedRoute;
